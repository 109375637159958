@import '~app/mixins';

.app {
  color: $color-grey;

  .top {
    text-align: center;
    margin: 25px 0;

    .title {
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .sectionTitle {
    border-top: 2px solid #ccc;
    margin: 20px 0;
    padding-top: 14px;
    font-size: 16px;
    font-weight: bold;
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    label {
      margin-bottom: 4px;
      font-weight: normal;
    }

    > div {
      width: 48%;
      margin-bottom: 25px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }
}
